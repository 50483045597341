


























import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import OrdersModule from "@/store/modules/order";
import { Business, Role } from "@/types";

const { mapGetters: OrderGetters, mapActions: OrderActions } =
  createNamespacedHelpers("NewOrders");

export default Vue.extend<any, any, any, any>({
  name: "NewOrders",
  data: () => ({}),
  computed: {
    ...OrderGetters(["getNewOrderCount"]),
    role(): Role {
      return this.$store.getters.role;
    },
    business(): Business {
      return this.role.business;
    },
    newOrders(): number {
      return this.getNewOrderCount || 0;
    },
  },
  watch: {
    role() {
      //this.checkForNewOrders();
    },
  },
  created() {
    this.checkForNewOrders();
  },
  methods: {
    ...OrderActions(["countNewOrders"]),
    checkForNewOrders() {
      if (this.role) {
        this.countNewOrders(`?businessId=${this.business._id}`);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("NewOrders")) {
      this.$store.registerModule("NewOrders", OrdersModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("NewOrders");
  },
});
